<script setup>
import { computed } from 'vue';

const props = defineProps({
  message: String | Array,
});

const message = computed(() => {
  return Array.isArray(props.message) ? props.message.join(' ') : props.message;
});
</script>

<template>
  <div v-show="message">
    <p class="text-sm text-red-600">
      {{ message }}
    </p>
  </div>
</template>
